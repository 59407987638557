 

import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import { ChakraProvider, CSSReset, Box, VStack,Container,extendTheme } from '@chakra-ui/react';
 
import App from './App';
import Header from './Header';
import Footer from './Footer';
// https://codesandbox.io/s/jr6nf?file=/src/index.js:0-663
const colors = {
  brand: {
    50: "#ecefff",
    100: "#cbceeb",
    200: "#a9aed6",
    300: "#888ec5",
    400: "#666db3",
    500: "#4d5499",
    600: "#3c4178",
    700: "#2a2f57",
    800: "#181c37",
    900: "#080819"
  }
};
const config = {
  initialColorMode: "light",
  useSystemColorMode: false
};
const theme = extendTheme({ colors, config });
const rootElement = document.getElementById("root");
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ChakraProvider theme={theme}>
    <CSSReset />
    {/* <Box minHeight="100vh"  bgGradient='linear(to-b, #33CCCC, #33CCCC)' p={4}> */}
      <Header  />
        <App />
      <Footer />
    {/* </Box> */}
  </ChakraProvider> 
);
