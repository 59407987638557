import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import News from './News';
import NewsDetail from './NewsDetail';
 
import ReactGA  from 'react-ga4';
import { AppStateProvider } from './AppStateContext';
import ProcessPage from './ProcessPage';
import './style.css'; // Importa la hoja de estilos CSS
ReactGA.initialize('G-16DM5XE6K0');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });


function initializeReactGA() {
  ReactGA.initialize('G-16DM5XE6K0');
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}
function App() {
  useEffect(() => {
    initializeReactGA();

    // Restaurar la posición de desplazamiento al volver a la página
    window.history.scrollRestoration = 'manual';
    const cachedScrollPositions:any = {};

    const saveScrollPosition = () => {
      cachedScrollPositions[window.location.pathname] = window.pageYOffset;
    };

    const restoreScrollPosition = () => {
      const currentPosition = cachedScrollPositions[window.location.pathname] || 0;
      window.scrollTo(0, currentPosition);
    };

    window.addEventListener('beforeunload', saveScrollPosition);
    window.addEventListener('popstate', restoreScrollPosition);

    return () => {
      window.removeEventListener('beforeunload', saveScrollPosition);
      window.removeEventListener('popstate', restoreScrollPosition);
    };
  }, []);

  return (
    <AppStateProvider>
    <Router>
      <Routes>
        <Route path="/" element={<News />} />
        <Route path="/news/:nombre_noticia" element={<NewsDetail />} />
        <Route path="/how" element={<ProcessPage />} />
        
      </Routes>
    </Router>
    </AppStateProvider>
  );
}

export default App;
