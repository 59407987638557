import React from 'react';
import { Box, Heading, Text, Flex, Icon, Link } from '@chakra-ui/react';
import { FaNewspaper, FaBrain, FaChartLine, FaRobot, FaExclamationTriangle, FaExternalLinkAlt,FaEnvelope } from 'react-icons/fa';

const ProcessPage = () => {
  return (
    <Box
      bg="#edf3f8"
      _dark={{
        bg: '#0e121b',
      }}
      style={{ minHeight: 'calc(100vh - 50px)', display: 'flex', flexDirection: 'column' }}
    >
      <Flex
        bg="#edf3f8"
        _dark={{
          bg: '#0e121b',
        }}
        py={{ base: '4em', sm: '5em', md: '5em', lg: '5em', xl: '5em' }}
        w={{ base: 'full', md: 'full' }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          mx="auto"
          px={8}
          py={4}
          rounded={{ base: '', md: 'lg' }}
          shadow="lg"
          bg="white"
          _dark={{
            bg: 'gray.800',
          }}
          maxW="5xl"
          minHeight="50vh"
        >
          <Heading as="h1" size="xl" mb={4}>
            ¿Qué Hacemos?
          </Heading>
          <Text mb={6}>
            <Flex alignItems="center" mb={2}>
              <Icon as={FaNewspaper} color="blue.500" fontSize="xl" mr={2} />
              Recopilamos información de fuentes confiables y relevantes para ti.
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Icon as={FaBrain} color="green.500" fontSize="xl" mr={2} />
              Aplicamos técnicas avanzadas de Procesamiento del Lenguaje Natural (NLP) para analizar y comprender el contenido.
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Icon as={FaChartLine} color="purple.500" fontSize="xl" mr={2} />
              Identificamos patrones y tendencias al organizar noticias sobre temas similares.
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Icon as={FaRobot} color="orange.500" fontSize="xl" mr={2} />
              Utilizamos inteligencia artificial para generar resúmenes y detalles relevantes de cada noticia.
            </Flex>
           
            <Flex alignItems="center" mb={2}>
              <Icon as={FaExternalLinkAlt} color="teal.500" fontSize="xl" mr={2} />
              Explora más a fondo cada artículo con enlaces a las noticias completas.
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Icon as={FaExclamationTriangle} color="red.500" fontSize="xl" mr={2} />
              Trabajamos constantemente para mejorar y garantizar la precisión de la información que presentamos.
            </Flex>
            <Flex alignItems="center" mt={2}>
              <Icon as={FaEnvelope} color="gray.600" fontSize="xl" mr={2} />
              Si deseas contactarnos, no dudes en escribirnos a <Link href="mailto:contacto@niows.com"> contacto@niows.com</Link>.
            </Flex>
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ProcessPage;
