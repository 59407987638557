import React from "react";
import {
  chakra,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  Box,
  VStack,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Image,
  useBreakpointValue
} from "@chakra-ui/react";
import { useViewportScroll } from "framer-motion";
import { FaMoon, FaSun, FaHeart } from "react-icons/fa";
import {
  AiFillGithub,
  AiOutlineMenu,
  AiFillHome,
  AiOutlineInbox,
} from "react-icons/ai";

import {
  IoIosArrowDown,
} from "react-icons/io";
import { BsFillCameraVideoFill } from "react-icons/bs";
// import { Logo } from "@choc-ui/logo";
 

export default function App(){ const { toggleColorMode: toggleMode } = useColorMode();
const text = useColorModeValue("dark", "light");
const SwitchIcon = useColorModeValue(FaMoon, FaSun);
const bg = useColorModeValue("white", "gray.800");
const ref = React.useRef<HTMLInputElement>(null);
const [y, setY] = React.useState(0);
const height = ref.current ? ref.current.getBoundingClientRect() : 0;
const { scrollY } = useViewportScroll();
React.useEffect(() => {
  return scrollY.onChange(() => setY(scrollY.get()));
}, [scrollY]);
const cl = useColorModeValue("gray.800", "white");
const mobileNav = useDisclosure();
interface props {};
const lightLogo = "/logo-light.svg";
const darkLogo = "/logo-dark.svg";

const lightSpr = "/resource/image/sprcl-light.svg";
const darkSpr = "/resource/image/sprcl-dark.svg";
const isMobile = useBreakpointValue({ base: true, md: false }); // Determine if the device is mobile
const themeLogo = useColorModeValue(lightLogo, darkLogo);
const themeSpr = useColorModeValue(lightSpr, darkSpr);
const Section = (props: any) => {
  const ic = useColorModeValue("brand.600", "brand.50");
  const hbg = useColorModeValue("gray.50", "brand.100");
  const tcl = useColorModeValue("gray.900", "gray.50");
  const dcl = useColorModeValue("gray.500", "gray.50");
  return (
    <Link
      m={-3}
      p={3}
      display="flex"
      alignItems="start"
      rounded="lg"
      _hover={{
        bg: hbg,
      }}
    >
      <chakra.svg
        flexShrink={0}
        h={6}
        w={6}
        color={ic}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        {props.icon}
      </chakra.svg>
      <Box ml={4}>
        <chakra.p fontSize="sm" fontWeight="700" color={tcl}>
          {props.title}
        </chakra.p>
        <chakra.p mt={1} fontSize="sm" color={dcl}>
          {props.children}
        </chakra.p>
      </Box>
    </Link>
  );
};

const Features = (props: any) => {
  const hbg = useColorModeValue("gray.50", "brand.400");
  const hbgh = useColorModeValue("gray.100", "brand.500");
  const tcl = useColorModeValue("gray.900", "gray.50");
  return (
    <React.Fragment>
      <SimpleGrid
        columns={
          props.h
            ? {
                base: 1,
                md: 3,
                lg: 5,
              }
            : 1
        }
        pos="relative"
        gap={{
          base: 6,
          sm: 8,
        }}
        px={5}
        py={6}
        p={{
          sm: 8,
        }}
      >
        <Section
          title="Analytics"
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            />
          }
        >
          Get a better understanding of where your traffic is coming from.
        </Section>

        <Section
          title="Engagement"
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
            />
          }
        >
          Speak directly to your customers in a more meaningful way.
        </Section>

        <Section
          title="Security"
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            />
          }
        >
          Your customers&#039; data will be safe and secure.
        </Section>

        <Section
          title="Integrations"
          description={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
            />
          }
        >
          Connect with third-party tools that you&#039;re already using.
        </Section>

        <Section
          title="Automations"
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          }
        >
          Build strategic funnels that will drive your customers to convert
        </Section>
      </SimpleGrid>
      <Box
        px={{
          base: 5,
          sm: 8,
        }}
        py={5}
        bg={hbg}
        display={{
          sm: "flex",
        }}
      >
        <Stack
          direction={{
            base: "row",
          }}
          spacing={{
            base: 6,
            sm: 10,
          }}
        >
          <Box display="flow-root">
            <Link
              m={-3}
              p={3}
              display="flex"
              alignItems="center"
              rounded="md"
              fontSize="md"
              color={tcl}
              _hover={{
                bg: hbgh,
              }}
            >
              <chakra.svg
                flexShrink={0}
                h={6}
                w={6}
                color="gray.400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </chakra.svg>
              <chakra.span ml={3}>Watch Demo</chakra.span>
            </Link>
          </Box>

          <Box display="flow-root">
            <Link
              m={-3}
              p={3}
              display="flex"
              alignItems="center"
              rounded="md"
              fontSize="md"
              color={tcl}
              _hover={{
                bg: hbgh,
              }}
            >
              <chakra.svg
                flexShrink={0}
                h={6}
                w={6}
                color="gray.400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </chakra.svg>
              <chakra.span ml={3}>Contact Sales</chakra.span>
            </Link>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

const MobileNavContent = (
  <VStack   w="100%"
    pos="absolute"
    top={0}
    left={0}
    right={0}
    display={mobileNav.isOpen ? "flex" : "none"}
    flexDirection="column"
    p={2}
    pb={4}
    m={2}
    bg={bg}
    spacing={3}
    rounded="sm"
    shadow="sm"
    z-index="1"
  >
    <CloseButton
      aria-label="Close menu"
      justifySelf="self-start"
      onClick={mobileNav.onClose}
    />
    <Button w="full" variant="ghost" leftIcon={<AiFillHome />}>
      Dashboard
    </Button>
    <Button
      w="full"
      variant="solid"
      colorScheme="brand"
      leftIcon={<AiOutlineInbox />}
    >
      Inbox
    </Button>
    <Button w="full" variant="ghost" leftIcon={<BsFillCameraVideoFill />}>
      Videos
    </Button>
  </VStack>
);
return (
  <Flex z-index="1" position="fixed" w="100%" style={{ position: "fixed", zIndex: 99999 }}>

  <React.Fragment>
    <chakra.header
      ref={ref}
      shadow={y > height ? "sm" : undefined}
      transition="box-shadow 0.2s"
      bg={bg}
      borderTop="0px solid"
      borderTopColor="#fcef93ff"
      w="full"
      h="3.5rem" 
      overflowY="hidden"
      z-index="1"
    >
      <chakra.div h="4.5rem" mx="auto" maxW="1200px"  z-index="1">
        <Flex
          w="full"
          h="full"
          px="2"
          alignItems="center"
          justifyContent="space-between"
          
        >
          <Flex align="flex-start">
          <Link  rel="canonical" href="/">
            <HStack>
            {isMobile && (
              // <Image src='/logo.svg' alt="Logo" boxSize="2em"   style={{ imageRendering: "auto", marginTop: "-1rem"  }}/>
              <Image src={themeLogo} alt="Logo" boxSize="8em"  style={{ imageRendering: "auto", marginTop: "-1rem" }}/>
            )}
            {!isMobile && (
              <Image src={themeLogo} alt="Logo" boxSize="8em"  style={{ imageRendering: "auto", marginTop: "-1rem" }}/>
            )}
            </HStack>
          </Link>
        </Flex>
          {/* <Flex>
            <HStack
              spacing="5"
              display={{
                base: "none",
                md: "flex",
              }}
            > */}
              {/* <Popover>
                <PopoverTrigger>
                  <Button
                    bg={bg}
                    color="gray.500"
                    display="inline-flex"
                    alignItems="center"
                    fontSize="md"
                    _hover={{
                      color: cl,
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                    rightIcon={<IoIosArrowDown />}
                  >
                    Features
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  w="100vw"
                  maxW="md"
                  _focus={{
                    boxShadow: "md",
                  }}
                >
                  <Features />
                </PopoverContent>
              </Popover> */}
              {/* <Button
                bg={bg}
                color="gray.500"
                display="inline-flex"
                alignItems="center"
                fontSize="md"
                _hover={{
                  color: cl,
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                Blog
              </Button> */}
              {/* <Button
                bg={bg}
                color="gray.500"
                display="inline-flex"
                alignItems="center"
                fontSize="md"
                _hover={{
                  color: cl,
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                Pricing
              </Button> */}
            {/* </HStack>
          </Flex> */}
          <Flex justify="flex-end" align="center" color="gray.400">
            {/* <HStack
              spacing="5"
              display={{
                base: "none",
                md: "flex",
              }}
            >
              <Button colorScheme="brand" variant="ghost" size="sm">
                Sign in
              </Button>
              <Button colorScheme="brand" variant="solid" size="sm">
                Sign up
              </Button>
            </HStack> */}
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              marginTop="-1rem"
              ml={{
                base: "0",
                md: "3",
                xl: "1",
              }}
              onClick={toggleMode}
              icon={<SwitchIcon />}
            />
            {/* <IconButton
              display={{
                base: "flex",
                md: "none",
              }}
              aria-label="Open menu"
              fontSize="20px"
              color="gray.800"
              _dark={{
                color: "inherit",
              }}
              variant="ghost"
              icon={<AiOutlineMenu />}
              onClick={mobileNav.onOpen}
            /> */}
          </Flex>
        </Flex>
        {MobileNavContent}
      </chakra.div>
    </chakra.header>
  </React.Fragment></Flex>
);
};

