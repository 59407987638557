// helpers.js o utils.js (según el nombre que hayas elegido)

import countries from 'i18n-iso-countries';
import es from 'i18n-iso-countries/langs/es.json';

countries.registerLocale(es);

export  const addCountryNamesToItems = (items:any) => {
 
    return items.map((item:any) => {
    
      if (typeof item === 'object' && item !== null && 'country' in item) {
        let countryName = countries.getName(item.country, 'es', { select: 'official' });
        if(item.country=='xx') {countryName= ''} else{ countryName= countryName+' - '} 
        return { ...item, country_name: countryName  };
      }
    
      return item;
    });
  };
