import React, { useState, useEffect,useContext  } from 'react';
import { Flex, Button, useColorModeValue, Tooltip } from "@chakra-ui/react";
import { StarIcon, TimeIcon } from "@chakra-ui/icons";
import { AppStateContext } from './AppStateContext';
import { AppStateProvider } from './AppStateContext';   
type EndpointButtonProps = {
  onEndpointChange: () => void;
};
// const EndpointButton = ({ onEndpointChange  }: EndpointButtonProps) => {
const EndpointButton = ( ) => {
  const [buttonText, setButtonText] = useState('Recientes');
  const [buttonIcon, setButtonIcon] = useState(<TimeIcon />);
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const buttonBgColor = useColorModeValue('gray', 'grayDark');
  const buttonTextColor = useColorModeValue('white', 'gray.800');

  const { currentMode } = useContext(AppStateContext);
  const { updateCurrentMode } = useContext(AppStateContext);
  useEffect(() => {
    // Recuperar el estado contrario almacenado en localStorage
    // const storedCurrentMode = localStorage.getItem('currentMode');
 

    // if (!storedCurrentMode) {
    //   localStorage.setItem('currentMode', 'Destacadas');
 
    // }
    // console.log('estado botón')
    // console.log(currentMode)
    if (currentMode) {
      setButtonText(currentMode === 'Recientes' ? 'Destacadas' : 'Recientes');
      setButtonIcon(currentMode === 'Recientes' ? <StarIcon /> : <TimeIcon />);
    }
  }, [currentMode]);

  const handleEndpointChange = () => {
    
    const newMode = currentMode === 'Recientes' ? 'Destacadas' : 'Recientes';
     updateCurrentMode(newMode);
 
    setButtonText(newMode);
    setButtonIcon(newMode === 'Recientes' ? <TimeIcon /> : <StarIcon />);
 
    // onEndpointChange();
 
  };

  return (
 
    <Flex
      position="fixed"
      top={{ base: "0.85em", sm: "1rem" }}
      right={{ base: "18vw", sm: "10vw", md: "14vw", lg: "6vw", xl: "23vw" }}
      zIndex={100000}
      width="100%"
      justifyContent="flex-end"
    >
      <Button
        onClick={handleEndpointChange}
        leftIcon={buttonIcon}
        color="current"
        bg={bgColor}
        size={{ base: "xxs", sm: "xs", md: "xs" }}
        paddingX={2}
        paddingY={1}
      >
        {buttonText}
      </Button>
    </Flex> 
  
  );
};

export default EndpointButton;
