import React, { useState, useEffect } from 'react';
import {
  Box,
  useBreakpointValue,
  Flex,
  Link,
  Image,
  chakra,
  Button,
  useColorModeValue,
  Skeleton,
  Tooltip,
  Icon,IconButton,Badge,Text ,  
  // Fade,
} from '@chakra-ui/react';
import { FaCalendarAlt,FaExclamationCircle,FaShare,FaCopy  } from 'react-icons/fa';
// import { CSSProperties } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/es';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Flag from 'react-world-flags';
// import { CircleFlag } from 'react-circle-flags';
// import EndpointButton from './EndpointButton';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HomeButton from './HomeButton';
import { Link as CustomLink } from 'react-router-dom';
moment.tz.setDefault('America/Santiago');

moment.locale('es');

const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();
// console.log(lngDetector.detect('This is a test.', 1)[0][0]);
type NewsItem = {
  id: number;
  title: string;
  summary: string;
  date_creation: string;
  country: string;
  category: string;
  image: any;
  urls: { url: string; site: string; title: string ; date_creation: string }[];
  related:{ id: string; title: string; site: string; url: string ; date_creation: string ; shortcut:string }[];
  relatedNews?: { title: string; url: string }[];
  image_author: string;
  color: string; // Agregar la propiedad 'color' de tipo string
  color_text: string; // Agregar la propiedad 'color_text' de tipo string
  ask:string;
  video_url:string;
  spoiler:number;
  showSpoiler:boolean;
};

 
const NewsDetail = ( ) => {

  const [userTimezone, setUserTimezone] = useState('');

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(userTimezone);
  }, []);
  
  function formatWithDayName(date:any, timezone:any) {
    const formattedDate = moment.utc(date).tz(timezone);
    const dayName = formattedDate.format('dddd');
    const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    const dateTime = formattedDate.format('LL LT');
    return `${capitalizedDayName}, ${dateTime}`;
  }


  const isMobile = useBreakpointValue({ base: true, md: false });
 
  // const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
 
 

 
  // const handleCategoryClick = (category: string | null) => {
  //   setSelectedCategory(category);
  // };
  const imageUrl:any= 'https://niows.com/resources/image/news/'
  const endpointUrl = 'https://niows.com/'; // URL completa de la API
 
   
  const { nombre_noticia } = useParams();
  const [item, setNoticia] = useState<NewsItem | null>(null);
  
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

    // Modify the handleSpoilerClick function to toggle the showSpoiler property of the item
    const handleSpoilerClick = () => {
      setNoticia((prevItem:any) => ({
        ...prevItem,
        showSpoiler: !prevItem.showSpoiler,
      }));
    };

  // console.log(nombre_noticia)
  useEffect(() => {
    const fetchNoticia = async () => {
      try{
        const response = await axios.get(`${endpointUrl}api/detail/${nombre_noticia}`);
        // setTimeout(() => {
          if (response.data.length === 0) {
            setHasError(true)}
            else{
              setNoticia(response.data[0]);
            }
          
         
        // }, 2000); 
      }catch(error){
        console.error("Error al obtener las noticias:", error);
        setHasError(true);
      }
    };
    setIsLoading(false);
    fetchNoticia();
  }, [nombre_noticia]);
  

  const urls: { url: string; site: string; title: string; date_creation: string }[] = item?.urls
  ? Array.isArray(item.urls)
    ? item.urls
    : JSON.parse(item.urls)
  : [];


  const related: { id: number; title: string; site: string; url: string; date_creation: string; shortcut: string }[] = item?.related
  ? Array.isArray(item.related)
    ? item.related
    : JSON.parse(item.related)
  : [];

// Ordenar el arreglo por fecha de la más nueva a la más antigua
related.sort((a, b) => new Date(b.date_creation).getTime() - new Date(a.date_creation).getTime());

// Mostrar solo las 10 primeras entradas
const firstRelated = related.slice(0, 6);



  const sortedUrls = [...urls].sort((a, b) =>
  moment(b.date_creation).diff(moment(a.date_creation))
);

  const handleEndpointChange = () => {
    setSortBy(sortBy === "urls_count" ? "id" : "urls_count")
  };

  // const buttonColorScheme = useColorModeValue('gray', 'grayDark');
  // const buttonBgColor = useColorModeValue('gray', 'grayDark');
  const linkColor = useColorModeValue('#171d29', 'white');

  const placeHolderImage = useColorModeValue('#e0e0e0', '#171d29');
  const [sortBy, setSortBy] = useState("urls_count");
 
  
  // let previousHour = '';

  const groupedUrls: { [key: string]: { url: string; site: string; title: string; date_creation: string }[] } = {};
  
  sortedUrls.forEach((urlObj: { url: string; site: string; title: string; date_creation: string }) => {
    
    const formattedDate = moment.utc(urlObj.date_creation).tz(userTimezone);
    const currentHour = moment(formattedDate).format('HH');
    if (currentHour in groupedUrls) {
      groupedUrls[currentHour].push(urlObj);
    } else {
      groupedUrls[currentHour] = [urlObj];
    }
  });
  
  const groupedHours = Object.keys(groupedUrls).sort((a, b) => b.localeCompare(a));
  

  const handleShare = async () => {
    if (navigator.share) {
      try {
        const currentUrl = window.location.href;
        await navigator.share({ url: currentUrl });
        // console.log('Se compartió la URL correctamente.');
      } catch (error) {
        console.error('Error al compartir la URL:', error);
      }
    } else {
      console.warn('El navegador no admite la API de compartir.');
      const currentUrl = window.location.href;
  
      if (document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.value = currentUrl;
        textarea.style.position = 'fixed'; // Para que no afecte al diseño de la página
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        // console.log('URL copiada al portapapeles correctamente.');
      } else {
        // console.error('El navegador no admite la copia al portapapeles.');
      }
    }
  };
  // const videoWidth = isMobile ? "100%" : "560px";
  // const videoHeight = isMobile ? "auto" : "315px";

  const isValidUrl = (url:any) => {
    const urlPattern = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return urlPattern.test(url);
  };

  if (isLoading) {
    return (

      <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
      <Box>
        <Skeleton width="55vw" height="80vh" borderRadius="8px" />
      </Box>
    </Flex>
    );
  }

 

console.log(item);

  if (hasError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
        flexDirection="column"
      >
        <FaExclamationCircle size={72} color="white" />
        <Text fontSize="xl" fontWeight="bold" color="white.500" textAlign="center">
          ¡Oops! Parece que no se pudo cargar la noticia. Inténtalo de nuevo más tarde.
        </Text>
        <Button
          as={RouterLink}
          to="/"
          colorScheme="blue"
          marginTop="4"
          size="lg"
          fontSize="md"
        >
          Volver a la página principal
        </Button>
        <HomeButton />
      </Box>
    );
  }

  if (!item) {
    return null;
  }
    return (
      <>
      <Helmet>
        <title>{item.title}</title>
        <meta property="og:title" content={item.title} />
        <meta property="og:image" content={item.image} />
        <meta property="og:description" content={item.summary} />
      </Helmet>
      <Box  bg="#edf3f8"
                _dark={{
                  bg: '#0e121b',
                }} style={{ minHeight: 'calc(100vh - 50px)', display: 'flex', flexDirection: 'column' }}>
     <Flex
                key={item.id}
                bg="#edf3f8"
                _dark={{
                  bg: '#0e121b',
                }}
                px={{ base: '0', md: '' }}
                py={{ base: "4em", sm: "5em", md: "5em" , lg: "5em", xl: "5em" }}
                w={{ base: 'full', md: 'full' }}
            
                alignItems="center"
                justifyContent="center"
              >
                
                <Box   
                  mx="auto"
                  px={8}
                  py={4}
                  rounded={{ base: '', md: 'lg' }}
                  shadow="lg"
                  bg="white"
                  _dark={{
                    bg: 'gray.800',
                  }}
                  maxW="5xl"
                  minHeight="50vh"
                >

{isMobile &&  (
                        <figure style={{  display: 'inline-block' }}>
                          {item.image_author ? (
                            <React.Fragment>
                           <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <figure style={{ position: 'relative' }}>
                              <Image
                                src={imageUrl + btoa(item.id.toString()) + '.webp'}
                                alt=""
                                style={{
                                  width: '85vw',
                                  height: '16em',
                                  objectFit: 'cover',
                                  borderRadius: '10px',
                                  objectPosition: 'top',
                                  transition: 'opacity 1s linear'
                                }}
                              />
                              <figcaption
                                style={{
                                  position: 'absolute',
                                  bottom: 0,
                                  left: 0,
                                  width: '100%',
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Ajusta el valor de transparencia según tus preferencias
                                  color: 'white',
                                  padding: '10px',
                                  textAlign: 'center',
                                  fontStyle: 'italic',
                                  fontSize: 'sm'
                                }}
                              >
                                Fuente: {item.image_author}
                              </figcaption>
                            </figure>
                          </div>

                            </React.Fragment>
                          ) : null
                       
                          }
                        </figure>
                     )}

          

                  <Box mt={2} display="flex"  >
                  
                    {!isMobile && (
                        <figure style={{ marginRight: '2em' }}>
                          {item.image_author ? (
                                            
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <figure style={{ position: 'relative',width: '200px' }}>
                                              <Image
                                                src={imageUrl + btoa(item.id.toString()) + '.webp'}
                                                alt=""
                                                style={{
                                                 width: '200px',
                                                 height: '300px',
                                                 objectFit: 'cover',
                                                 borderRadius: '10px',
                                                 objectPosition: 'center top',
                                                  transition: 'opacity 1s linear'
                                                }}
                                              />
                                              <figcaption
                                                style={{
                                                  position: 'absolute',
                                                  bottom: 0,
                                                  left: 0,
                                                  width: '100%',
                                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Ajusta el valor de transparencia según tus preferencias
                                                  color: 'white',
                                                  padding: '0.1em',
                                                  textAlign: 'center',
                                                   
                                                  fontSize: '0.7em',
                                                  borderRadius: '0.5em',
                                                  margin: '0.3em 0'
                                                 
                                                }}
                                              >
                                                Fuente: {item.image_author}
                                              </figcaption>
                                            </figure>
                                          </div>
                              
       
                                                 ) : (
                            <div
                              style={{
                                width: '200px',
                                height: '300px',
                                backgroundColor: placeHolderImage,
                                borderRadius: '10px',
                              }}
                            />
                          )}
                        </figure>
                     )}
                

                    <Box>
                      <Link
                        fontSize="2xl"
                        color="gray.700"
                        _dark={{
                          color: 'white',
                        }}
                        fontWeight="700"
                        _hover={{
                          color: 'gray.700',
                          _dark: {
                            color: ' white',
                          },
                          textDecor: 'none',
                          cursor: 'default'
                        }}
                      ><h1> {item.title}</h1>
              
                      </Link>
                      
          <Flex justifyContent="space-between" alignItems="center">
      
                    <chakra.span
                      fontSize="sm"
                      color="gray.600"
                      _dark={{ color: 'gray.300' }} fontWeight="semibold"
                    >
                      <Icon as={FaCalendarAlt} mr={2} />
                      {formatWithDayName(item.date_creation, userTimezone)}  - {item.category} 
                        <br/>
                      <chakra.span
                      fontSize="sm"
                      color="gray.400"
                      _dark={{ color: 'gray.600' }}
                    >
                      {item.ask !== 'None' && item.ask}
                    </chakra.span>

                    </chakra.span>
                    
                    {isMobile && (
                <Tooltip label="Compartir" placement="bottom">
                  <IconButton
                    icon={<FaShare />}
                    aria-label="Compartir"
                    variant="outline"
                    onClick={handleShare}
                  />
                </Tooltip>)}
                {!isMobile && (
                <Tooltip label="Copiar enlace" placement="bottom">
                  <IconButton
                    icon={<FaCopy />}
                    aria-label="Copiar enlace"
                    variant="outline"
                    onClick={handleShare}
                  />
                </Tooltip>)}
                  </Flex>


                  <chakra.p
  mt={2}
  color={item.spoiler === 1 && !item.showSpoiler ? 'black' : 'gray.600'}
  textDecoration={item.spoiler === 1 && !item.showSpoiler ? 'none' : 'none'}
  _dark={{
    color: item.spoiler === 1 && !item.showSpoiler ? 'gray.300' : 'gray.600',
  }}
  onClick={handleSpoilerClick}
  _hover={{ cursor: item.spoiler === 1 ? 'pointer' : 'default' }}
  style={{
    position: 'relative',
    borderRadius: '4px',
    padding: '8px',
    minHeight: '100px', // Ajusta la altura para centrar verticalmente el texto
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  {item.spoiler === 1 && !item.showSpoiler ? (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backdropFilter: 'blur(5px)',
          borderRadius: '4px',
        }}
      />  {item.summary}
      <div style={{ position: 'absolute', zIndex: 1 }}>
        Este contenido es un spoiler. Haz clic aquí para verlo.
      </div>
    </>
  ) : (
    item.summary
  )}
</chakra.p>
                    
                      <Box marginY="2em"     
                      display="flex"
                      justifyContent="center"
                      alignItems="center">
                    {item.video_url && isValidUrl(item.video_url) && (
                      <iframe
                        width={isMobile ? "auto" : "560"}
                        height={isMobile ? "auto" : "315"}
                        src={item.video_url}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}
                  </Box>

                  <Box>
      {/* <Text fontSize="lg" fontWeight="bold" mb="4">Noticias Relacionadas:</Text> */}

      {related.length > 0 && (
      <Badge  fontSize="md" fontWeight="medium" mb="4">
        Noticias Relacionadas
      </Badge> )}
      {firstRelated.map((item:any, index:any) => (
        
      <CustomLink key={index} rel="canonical" to={'/news/' + item.shortcut} color="gray.700">
      <Box display="flex" alignItems="center" style={{ margin: '10px auto' }}>
      {isMobile &&  (
        <Box px={'0.2em'} py={'0.1em'}>
     
          <LazyLoadImage
            src={imageUrl + btoa(item.id.toString()) + '.webp'}
            alt=""
            effect="opacity"
            style={{
              width: '20vw',
              objectFit: 'cover',
              borderRadius: '5px',
              objectPosition: 'top',
              transition: 'opacity 1s linear',
            }}
          />
       
        </Box>
          )}

{!isMobile &&  (
        <Box px={'0.2em'} py={'0.1em'}>
     
          <LazyLoadImage
            src={imageUrl + btoa(item.id.toString()) + '.webp'}
            alt=""
            effect="opacity"
            style={{
              width: '5vw',
              objectFit: 'cover',
              borderRadius: '5px',
              objectPosition: 'top',
              transition: 'opacity 1s linear',
            }}
          />
       
        </Box>
          )}

        <Box px={1}>
          <Box
             width={{ base: '60vw', sm: "30vw", md: "30vw", lg: '30vw', xl: '30vw' }} 
            bg={item.color}
            color={item.color_text}
            fontSize="xs"
            rounded="md"
            lineHeight="1.2"
            display="block" fontWeight="semibold"
          >          
          <Badge
          style={{ marginRight: '4px', marginLeft: 'auto', marginTop: '-2px' }}
          borderRadius="full"
          colorScheme="gray"
          fontSize="2xs" fontWeight="semibold"
        >
          {moment(item.date_creation).format('D MMM')}
        
        </Badge>
            {item.title}
      
          </Box>
        </Box>
      </Box>
      </CustomLink>
 
      ))}
    </Box>

          
                      <Badge    fontSize="md" fontWeight="medium" mb="4" mt={6}>
                        Fuentes de la noticia
      </Badge>
                      <Flex mt={1}>
                        
                      {urls && urls.length > 0 && (
        <Flex mt={4} flexWrap="wrap">
          {/* <h2>Cronologia de noticias</h2> */}


          {groupedHours.map((hour: string) => (
        <Flex key={hour} flexDirection="column" mr={3} mb={3}>
          <Box fontSize="sm" color="gray.500" fontWeight="light" mb={1}>
            
          <Badge style={{ marginRight: '4px' }}
          borderRadius="full"
          px={2}
          colorScheme="blue"
          fontSize="sm"
        >
          {moment(hour, 'HH').format('LT')}
        </Badge>

        
    </Box>
    {groupedUrls[hour].map((urlObj: { url: string; site: string; title: string; date_creation: string }, index: number) => (
      
      <Flex key={index} alignItems="center"  mr={1.5} mb={1.5}>
        <Box as="img" src={`/resources/image/${urlObj.site}-32x32.png`} alt="" borderRadius="5px" marginRight="4px" boxSize="24px" />
        <Link
          href={lngDetector.detect(urlObj.title, 1)[0][0] === 'english' ?
          `https://translate.google.com/translate?sl=auto&tl=es&hl=es-419&u=${urlObj.url}&client=webapp` :
          urlObj.url
        }
          target="_blank"
          rel="noopener noreferrer"
          fontSize="sm"
          color="blue.500"
          display="flex"
          alignItems="center"
          style={{ fontWeight: 'semibold', fontSize: '0.8em', textDecoration: 'none', color: linkColor }}
        >
          <Box fontWeight="semibold">  {urlObj.title}  {lngDetector.detect(urlObj.title, 1)[0][0] === 'english' && (
        <>
         (traducida al español)  
        </>
      )}
     
         </Box>
        </Link>
      </Flex>
    ))}
  </Flex>
))}
  </Flex>
)}


                  
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </Flex>
              <HomeButton />
            
           
    </Box>
    </>
    );
    

};
export default NewsDetail;
