import React, { useState, useEffect ,useContext,useRef  } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  useBreakpointValue,
  Flex,
  Button,
 
  chakra,
  // Button,
  useColorModeValue,
  Skeleton,
   Text,
  Icon, Badge,Heading ,Menu, MenuButton, MenuList, MenuItem,
   Fade,
} from '@chakra-ui/react';
import { IoIosArrowDown,IoIosArrowForward,IoIosArrowBack  } from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBook, faFutbol,  faSchool, faUsers, faTree ,  faNewspaper,   faVideo, faMicrochip ,faLayerGroup,faRandom,faTv } from '@fortawesome/free-solid-svg-icons';
// import { FaMoneyBill, FaFlask, FaTree, FaBalanceScale, FaStar, FaBus, FaMobileAlt, FaExclamationTriangle, FaFilm, FaUsers, FaBuilding, FaCommentAlt, FaGamepad, FaGlobe, FaMusic, FaAmbulance, FaFlag, FaTv, FaUtensils, FaUserFriends, FaNewspaper, FaChartLine, FaHeartbeat, FaPalette, FaCloudSun, FaBullhorn } from 'react-icons/fa';
  
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
// import { CSSProperties } from 'react';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/es';
import { faSun, faCloud, faCloudSun, faCloudRain, faSnowflake,faSmog,faCloudSunRain,faCloudMoon,faCloudMoonRain,faCloudShowersHeavy,faMoon,faQuestionCircle,faCheckCircle ,faPuzzlePiece   } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import Week from './week';
import { Tooltip } from '@chakra-ui/react';
import { FaCalendarAlt,FaExclamationCircle, FaNewspaper ,FaSync,FaMoneyBillAlt, FaDollarSign,FaAngleUp, FaAngleDown,FaTemperatureLow } from 'react-icons/fa';
 
// import Flag from 'react-world-flags';
// import { CircleFlag } from 'react-circle-flags';
import EndpointButton from './EndpointButton';
import Month from './month';
import Video from './Video';
// import Ad from './ad';
import { AppStateContext } from './AppStateContext';

import { addCountryNamesToItems } from './Helpers'; 
 
import { WiCelsius } from 'react-icons/wi';
import axios from 'axios';
// import countries from 'i18n-iso-countries';
// import es from 'i18n-iso-countries/langs/es.json';

// countries.registerLocale(es);

// const addCountryNamesToItems = (items:any) => {
 
//   return items.map((item:any) => {
  
//     if (typeof item === 'object' && item !== null && 'country' in item) {
//       let countryName = countries.getName(item.country, 'es', { select: 'official' });
//       if(item.country=='xx') countryName= 'Global'
//       return { ...item, country_name: countryName+' - ' };
//     }
  
//     return item;
//   });
// };

moment.tz.setDefault('America/Santiago');

moment.locale('es');




type NewsItem = {
  id: number;
  title: string;
  summary: string;
  date_creation: string;
  country: string;
  country_name: string;
  category: string;
  image: any;
  urls?: { id: number,url: string; site: string; title: string }[];
  relatedNews?: { title: string; url: string }[];
  image_author: string;
  color: string; // Agregar la propiedad 'color' de tipo string
  color_text: string; // Agregar la propiedad 'color_text' de tipo string
  shortcut:string;
  urls_count:number;
  category_group:string;
  spoiler:number;
  showSpoiler:boolean;
};



// type Props = {
//   news: NewsItem[];
// };

// type Direction = 'up' | 'down';
const FIVE_MINUTES = 1 * 60 * 1000; // 5 minutes in milliseconds change to one minute.
const News = ( ) => {
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false });
  // const imageWidth = useBreakpointValue({ base: '100%', md: '200px' });
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // const [selectedNews, setSelectedNews] = useState<NewsItem | null>(null);
  // const [showShareDialog, setShowShareDialog] = useState(false);
  // const [expandRelatedNews, setExpandRelatedNews] = useState(false);
  // const [displayText, setDisplayText] = useState('Lo mas comentado');
  const [news, setNews] = useState<NewsItem[]>([]); // Agregar esta línea para declarar el estado "news"
  const [yesterday, setYesterday] = useState<NewsItem[]>([]); // Agregar esta línea para declarar el estado "news"
  // <Week ref={weekRef}/>
  // <Month  ref={monthRef}/>
  const weekRef = useRef<any>();
  const monthRef = useRef<any>();
  // const handleShareClick = () => {
  //   setShowShareDialog(true);
  // }; 

  // const handleRelatedNewsClick = () => {
  //   setExpandRelatedNews(!expandRelatedNews);
  // };

  // const handleNewsLinkClick = (url: string) => {
  //   window.open(url, '_blank');
  // };
 
  // const handleCategoryClick = (category: string | null) => {
  //   setSelectedCategory(category);
  //   setSelectedCategoryGroups([]); // Reiniciar la selección del grupo al cambiar la categoría
  // };
  const imageUrl:any= 'https://niows.com/resources/image/news/'
  const endpointUrl: any = 'https://niows.com/';
  // const endpointUrl: any = 'http://10.10.10.100:8888/';
  const [endpoint, setEndpoint] = useState(endpointUrl+'api/news');
  const [endpointYesterday, setEndpointYesterday] = useState(endpointUrl+'api/yesterday');
  const categoryGroups: { [key: string]: any } = {
    'Noticias': faNewspaper,
    'Tecnología': faMicrochip,
    'Entretenimiento': faTv ,
    'Naturaleza': faTree,
    'Sociedad': faUsers,
    'Deportes': faFutbol,
    'Miscelánea': faRandom,
  };

  const categoryColors: { [key: string]: string } = {
    'Noticias': '#7aa9f0ff',
    'Tecnología': '#a176d9ff',
    'Entretenimiento': '#d486c2ff',
    'Naturaleza': '#a2dd8bff',
    'Sociedad': '#86d5d7ff',
    'Deportes': '#e59a6eff',
    'Miscelánea': '#db8181ff',
  };

  const allCategoryGroups = [
    'Noticias',
    'Tecnología',
    'Entretenimiento',
    'Naturaleza',
    'Sociedad',
    'Deportes',
    'Miscelánea',
     
  ];
  const { currentMode } = useContext(AppStateContext);
  const { updateCurrentMode } = useContext(AppStateContext);
  
 

  

  const buttonBgColorCategoryGroup = useColorModeValue('#edf2f7', '#2f3a4d');
  const buttonTextColorCategoryGroup = useColorModeValue('black', 'white');
  const buttonHoverCategoryGroup = useColorModeValue('#cad1d8', '#3a475c');
  const buttonBgActiveCategoryGroup = useColorModeValue('white', 'gray.100');
  
  


  const placeHolderImage = useColorModeValue('#e0e0e0', '#171d29');
  // const [sortBy, setSortBy] = useState("urls_count");

  const [totalUrls, setTotalUrls] = useState([]);
 

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState<number | null>(null); 
  const [lastFetchTimeYesterday, setLastFetchTimeYesterday] = useState<number | null>(null); 
const [selectedCategoryGroups, setSelectedCategoryGroups] = useState<string[]>(allCategoryGroups);

const [userTimezone, setUserTimezone] = useState('');

useEffect(() => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  setUserTimezone(userTimezone);
}, []);

useEffect(() => {
  const storedCategoryGroups = localStorage.getItem('selectedCategoryGroups');
  if (storedCategoryGroups) {
    setSelectedCategoryGroups(JSON.parse(storedCategoryGroups));
  }
}, []);

useEffect(() => {
  localStorage.setItem('selectedCategoryGroups', JSON.stringify(selectedCategoryGroups));
}, [selectedCategoryGroups]);


const fetchDataYesterday = () => {
  fetch(endpointYesterday)
    .then((response) => response.json())
    .then((data) => {
      if (data.length === 0) {
        setHasError(true);
        console.log('error')
      } else {

        // data.sort(compareFunction);
        data = addCountryNamesToItems(data)
        
        setYesterday(data);
        setNews(data)
        // Calcular el total de URLs
        const totals = data.map((item:any) => item.urls_count);
        setTotalUrls(totals);
      }

       
      setLastFetchTimeYesterday(Date.now()); // Guardar la fecha de carga actual en milisegundos
      localStorage.setItem('yesterdayData', JSON.stringify(data)); // Guardar la información en el localStorage
      localStorage.setItem('lastFetchTimeyesterday', Date.now().toString()); // Guardar la fecha de carga en el localStorage
      
    })
    .catch((error) => {
      console.log(error);
 
      setHasError(true);
    });
};

const showWeek = () => {
  
  if (weekRef.current && typeof weekRef.current.fetchData === 'function'){
    // weekRef.current.fetchData();
 
    let  storedData = localStorage.getItem('weekData');
  if(!storedData){ 
    weekRef.current.fetchData();
    storedData = localStorage.getItem('weekData');
  }
  if(storedData){
   const parsedData = JSON.parse(storedData);
   setNews(processData(parsedData));
  }
 
}
}


const showYesterday = () => {
  
  let  storedData = localStorage.getItem('yesterdayData');

  // if (!storedData) {
    yesterdayUpdate();
  // }
 
  if(storedData){
   const parsedData = JSON.parse(storedData);
   setNews(processData(parsedData));
  }
 
}

const showMonth = () => {
  if (monthRef.current) {

  let storedData = localStorage.getItem('monthData');
  if(!storedData){
    monthRef.current.fetchData();
    storedData = localStorage.getItem('monthData');
   }
   if(storedData){
    const parsedData = JSON.parse(storedData);
    setNews(processData(parsedData));
   }

  }
}
 


const [periodIndex, setPeriodIndex] = useState(0);
const periodList = ['Hoy','Ayer','Semana','Mes'  ];
const [selectedPeriod, setSelectedPeriod] = useState('Hoy'); 

const   goBackward= () => {
  // console.log(periodIndex)
  if (periodIndex < periodList.length - 1) {
    setPeriodIndex((prevIndex) => prevIndex + 1);
  } else {
    setPeriodIndex(0);
  }
  changePeriod(); // Call changePeriod after going forward
};

const goForward = () => {
  
  if (periodIndex > 0) {
    setPeriodIndex((prevIndex) => prevIndex - 1);
  } else {
    setPeriodIndex(periodList.length - 1);
 
  }
  // changePeriod(); // Call changePeriod after going backward
 
};

const [weatherData, setWeatherData] = useState<any>(null); 
const [showForecast, setShowForecast] = useState<boolean>(false); 



const [latitude, setLatitude] = useState<number | null>(null);
const [longitude, setLongitude] = useState<number | null>(null);

const getWeatherIcon = (weatherCode: any, size?: SizeProp) => {
  const iconSize = size || "1x"; // Tamaño predeterminado si no se proporciona

  switch (weatherCode) {
    case '01d':
      return <FontAwesomeIcon icon={faSun} size={iconSize} />;
    case '01n':
      return <FontAwesomeIcon icon={faMoon} size={iconSize} />;
    case '02d':
      return <FontAwesomeIcon icon={faCloudSun} size={iconSize} />;
    case '02n':
      return <FontAwesomeIcon icon={faCloudMoon} size={iconSize} />;
    case '03d':
    case '03n':
    case '04d':
    case '04n':
      return <FontAwesomeIcon icon={faCloud} size={iconSize} />;
    case '09d':
    case '09n':
      return <FontAwesomeIcon icon={faCloudRain} size={iconSize} />;
    case '10d':
      return <FontAwesomeIcon icon={faCloudSunRain} size={iconSize} />;
    case '10n':
      return <FontAwesomeIcon icon={faCloudMoonRain} size={iconSize} />;
    case '11d':
    case '11n':
      return <FontAwesomeIcon icon={faCloudShowersHeavy} size={iconSize} />;
    case '13d':
    case '13n':
      return <FontAwesomeIcon icon={faSnowflake} size={iconSize} />;
    case '50d':
    case '50n':
      return <FontAwesomeIcon icon={faSmog} size={iconSize} />;
    default:
      console.log(weatherCode);
      return null;
  }
};
 




const getWeatherDescription = (description:any) => {
  switch (description) {
    case 'Clear':
      return 'Despejado';
    case 'Clouds':
      return 'Nublado';
    case 'Rain':
      return 'Lluvia';
    case 'Snow':
      return 'Nieve';
    default:
      return 'Otros';
  }
};

  // Función para recortar a 6 decimales
  const recortarDecimal = (valor:any) => {
    return Number(valor.toFixed(6));
  };

  const [locationPermission, setLocationPermission] = useState<boolean | null>(false);
  const [showWeatherButton, setShowWeatherButton] = useState<boolean | null>(false);


  const handleRequestLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setShowWeatherButton(false);
        setLatitude(recortarDecimal(position.coords.latitude));
        setLongitude(recortarDecimal(position.coords.longitude));
        setShowForecast(true);
      },
      (error) => {
        console.error('Error getting current position:', error);
      }
    );
  };

  useEffect(() => {
    setShowWeatherButton(true);
    setLatitude(recortarDecimal(-33.43909641447455));
    setLongitude(recortarDecimal(-70.64681053161623));
    setLocationPermission(false);
  
}, [])
 

useEffect(() => {
  checkGeolocationPermission();
}, []);

async function checkGeolocationPermission() {
  try {
    const result = await navigator.permissions.query({ name: 'geolocation' });

    if (result.state === 'granted') {
      handleRequestLocationPermission()
  
    }
  } catch (error) {
    console.error('Error checking geolocation permission:', error);
    
  }
}

  // useEffect(() => {
  //   if (locationPermission) {
  //     if (navigator.geolocation) {
  //       // Aquí puedes realizar acciones con navigator.geolocation cuando tienes permisos
  //       // Por ejemplo, obtener la posición actual
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           setLatitude(recortarDecimal(position.coords.latitude));
  //           setLongitude(recortarDecimal(position.coords.longitude));
  //           // Realiza acciones con la ubicación obtenida
  //         },
  //         (error) => {
  //           console.error('Error getting geolocation:', error);
  //         }
  //       );
  //     } else {
  //       console.error('Geolocation is not supported by this browser.');
  //     }
  //   }
  // }, [locationPermission]);

  // async function checkGeolocationPermission() {
  //   try {
  //     const result = await navigator.permissions.query({ name: 'geolocation' });
  
  //     if (result.state === 'granted') {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           setShowWeatherButton(false);
  //           setLatitude(recortarDecimal(position.coords.latitude));
  //           setLongitude(recortarDecimal(position.coords.longitude));
  //           setShowForecast(true);
  //         },
  //         (error) => {
  //           console.error('Error getting current position:', error);
  //         }
  //       );
  //     } else if (result.state === 'prompt') {
  //       setShowWeatherButton(true);
  //       setLatitude(recortarDecimal(-33.43909641447455));
  //       setLongitude(recortarDecimal(-70.64681053161623));
  //       setLocationPermission(false);
  //     } else {
  //       setShowWeatherButton(true);
  //       setLatitude(recortarDecimal(-33.43909641447455));
  //       setLongitude(recortarDecimal(-70.64681053161623));
  //       setLocationPermission(false);
  //     }
  //   } catch (error) {
  //     console.error('Error checking geolocation permission:', error);
  //   }
  // }
  

// useEffect(() => {
//   if (locationPermission !== null) { // Verifica si locationPermission es true o false
 
//     checkGeolocationPermission();
//   }
// }, [locationPermission]);



const [ufValue, setUfValue] = useState(null);
const [dolarValue, setDolarValue] = useState(null);

useEffect(() => {
  fetch('https://mindicador.cl/api')
    .then(response => response.json())
    .then(data => {
      setUfValue(data.uf.valor);
      setDolarValue(data.dolar.valor);
    })
    .catch(error => console.error('Error fetching data:', error));
}, []); 

useEffect(() => {
  if (latitude && longitude) {
    axios.get(`https://niows.com/public/weather.php?lat=${latitude}&lon=${longitude}`)
 
      .then(response => {
        setWeatherData(response.data);
    
      })
      .catch(error => {
        console.error(error);
      });
  }
}, [latitude, longitude]);


const [weatherData2, setWeatherData2] = useState<any>(null);
useEffect(() => {
  if(!weatherData2 && latitude && longitude){
  fetch(`https://niows.com/public/forecast.php?lat=${latitude}&lon=${longitude}`)
    .then(response => response.json())
    .then(data => setWeatherData2(data))
    .catch(error => console.error('Error fetching data:', error));
  }
}, [latitude, longitude]);

const enableForecast = () => {
  setShowForecast(true);
}

const disableForecast = () => {
  setShowForecast(false);
}

useEffect(() => {
  // Esta función se ejecutará después de que se actualice el estado periodIndex
  
  changePeriod();
 
}, [periodIndex]);

const changePeriod = () => {
  const currentPeriod = periodList[periodIndex];
  // Code specific to each case
 
  switch (currentPeriod) {
    case 'Mes':
      setSelectedPeriod('Destacadas del ' +currentPeriod);
      showMonth();
      break;
    case 'Semana':
      setSelectedPeriod('Destacadas de la ' +currentPeriod);
      showWeek();
      break;
    case 'Hoy':
      // Code for when the value is 'Today'
      setSelectedPeriod(currentMode +' de hoy '  );
      newsUpdate();
      break;
    case 'Ayer':
      setSelectedPeriod('Destacadas de ' +currentPeriod);
      showYesterday()
      break;
    default:
      break;
  }
 
};

const numberOfItemsToShow = isMobile ? 12 : 20;
const numberOfItemsToShowStart = isMobile ? 1 : 0;
  const fetchData = () => {
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 0) {
          setHasError(true);
        } else {
 
          // data.sort(compareFunction);
          data = addCountryNamesToItems(processData(data))
          
          setNews(data);

          // Calcular el total de URLs
          const totals = data.map((item:any) => item.urls_count);
          setTotalUrls(totals);
        }

        setIsLoading(false);
        setLastFetchTime(Date.now()); // Guardar la fecha de carga actual en milisegundos
        localStorage.setItem('newsData', JSON.stringify(data)); // Guardar la información en el localStorage
        localStorage.setItem('lastFetchTime', Date.now().toString()); // Guardar la fecha de carga en el localStorage
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setHasError(true);
      });
  };

  const processData = (jsonData: any[]) => {
 
    if (currentMode == "Destacadas"){
  
    const compareFunction = (a:any, b:any) => a.url_count_rownumber - b.url_count_rownumber;
    jsonData.sort(compareFunction);
    return jsonData 

  }else{
    // console.log('entra a recientes')
    const compareFunction = (a:any, b:any) => a.date_rownumber - b.date_rownumber;
    jsonData.sort(compareFunction);
    return jsonData 
    }
  };
  const handleSpoilerClick = (itemId: number) => {
    setNews((prevNews) =>
      prevNews.map((item) =>
        item.id === itemId ? { ...item, showSpoiler: true } : item
      )
    );
  };

  const newsUpdate = () => {
    // console.log('entra a newsUpdate')
 
    const storedData = localStorage.getItem('newsData');
    const storedFetchTime:any = localStorage.getItem('lastFetchTime');
    const shouldFetchData =
      !storedData || !storedFetchTime || Date.now() - storedFetchTime > FIVE_MINUTES;

    if (shouldFetchData) {
      fetchData();
    } else {
      
      const parsedData = JSON.parse(storedData);
 
      setNews(processData(parsedData));
      
      setIsLoading(false);
    }
    setSelectedPeriod(currentMode + ' de Hoy')
  }


  const yesterdayUpdate = () => {
    // const storedData = localStorage.getItem('yesterdayData');
    // const storedFetchTime:any = localStorage.getItem('lastFetchTimeYesterday');
    // const shouldFetchData = !storedData || !storedFetchTime || Date.now() - storedFetchTime > FIVE_MINUTES;

    // if (shouldFetchData) {
      fetchDataYesterday();
    // } else {
    //   const parsedData = JSON.parse(storedData);
    //   setNews(parsedData);
    
    // }
   
  }

  function formatWithDayName(date:any, timezone:any) {
    const formattedDate = moment.utc(date).tz(timezone);
    const dayName = formattedDate.format('dddd');
    const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    const dateTime = formattedDate.format('LL LT');
    return `${capitalizedDayName}, ${dateTime}`;
  }
  
 
  useEffect(() => {
   
     newsUpdate();
   }, [currentMode]);

 
  const handleCategoryGroupClick = (categoryGroup: string) => {
    // Verificar si el grupo seleccionado ya está activo
    const isGroupSelected = selectedCategoryGroups.includes(categoryGroup);
    
  
    // Si el grupo seleccionado ya está activo y es el único grupo seleccionado, no realizar cambios
    if (isGroupSelected && selectedCategoryGroups.length === 1) {
      return;
    }
  
    // Si el grupo seleccionado ya está activo, desactivarlo
    if (isGroupSelected) {
      const updatedGroups = selectedCategoryGroups.filter(group => group !== categoryGroup);
      setSelectedCategoryGroups(updatedGroups);
    } else {
      // Si el grupo seleccionado no está activo, activarlo
      setSelectedCategoryGroups([...selectedCategoryGroups, categoryGroup]);
    }
  };

  const [showHelpMessage, setShowHelpMessage] = useState(false);

  const handleHelpButtonClick = () => {
    setShowHelpMessage(!showHelpMessage);
  };

  <style>{`
  .btn.selected {
    background-color: gray;
    color: white;
  }
`}</style>

  
// if (1==1) {
  if (isLoading) {
    return (<>
{!isMobile &&  (
<Flex width="100%" height="100vh" pt="5em">

  <Flex width="33%" height="100%" alignItems="top" justifyContent="left">
    <Box>
      <Skeleton width={{ base: "18vw", sm: "18vw", md: "18vw", lg: "18vw", xl: "18vw" }} height="30vh" my={3} borderRadius="8px" />
      <Skeleton width={{ base: "18vw", sm: "18vw", md: "18vw", lg: "18vw", xl: "18vw" }} height="30vh" my={3} borderRadius="8px" />
    </Box>
  </Flex>
 
  <Flex width="33%" height="100%" alignItems="top" justifyContent="center">
    <Box>
    <Skeleton width={{ base: "50vw", sm: "50vw", md: "50vw", lg: "50vw", xl: "50vw" }} height="30vh" my={3} borderRadius="8px">
 
</Skeleton>
      <Skeleton width={{ base: "50vw", sm: "50vw", md: "50vw", lg: "50vw", xl: "50vw" }} height="30vh" borderRadius="8px" />
    </Box>
  </Flex>
  
  <Flex width="33%" height="100%" alignItems="top" justifyContent="right">
    <Box>
    <Skeleton width={{ base: "18vw", sm: "18vw", md: "18vw", lg: "18vw", xl: "18vw" }} height="62vh" my={3} borderRadius="8px" />
    </Box>
  </Flex>

</Flex>
)}

{ isMobile &&  (
<Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
      <Box>
        <Skeleton width={{ base: "80vw", sm: "60vw", md: "100vw", lg: "80vw", xl: "60vw"  }} height="80vh" borderRadius="8px"/>
                 
      </Box>
    </Flex>
    )}
    </> );
  }
 
  if (hasError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
        flexDirection="column"
      >
        <FaExclamationCircle size={72} color="white" />
        <Text fontSize="xl" fontWeight="bold" color="white.500" textAlign="center">
          ¡Oops! Parece que no se pudieron cargar las noticias. Inténtalo de nuevo más tarde.
        </Text>

     
      </Box>
    );
  }

  if (!news) {
    return null;
  }
  return (
    <>
    {/* <AppStateProvider> */}
       {/* <EndpointButton onEndpointChange={newsUpdate} />*/}
        <EndpointButton /> 
       {/* <EndpointButton onEndpointChange={handleEndpointChange} /> */}
       {/* {!isMobile &&  ( */}
       <div style={{ width: '100%' }}>
       <Week ref={weekRef}  show={isMobile || false}/>
       <Month  ref={monthRef}  show={isMobile || false}/>
       <Video show={isMobile || false} />
       {/* <Ad/> */}
          </div>
            {/* )} */}
     
            
 


 

{!isMobile ? (

      // Componente para pantalla pc
      <>
           <Flex 
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
        
            // pt="19"
            position="fixed"
            top={{ base: "0.8em", sm: "0.6em" }}
            right={{ base: "12em", sm: "7em", md: "7em" , lg: "7em", xl: "30em" }}
            zIndex={10000000000}
            flexDirection={{ base: 'row', md: 'row' }}

          >
        <Flex
            direction="row"
            position="fixed"
            top={{ base: "1.7rem", sm: "4.6vh", md: "1.8rem" , lg: "1.8rem", xl: "1.8rem" }}
            // right="30rem"
            right={{ base: "52vw" , sm: "60vw", md: "52" , lg: "20vw", xl: "30vw" }}
            transform="translateY(-50%)"
            zIndex={9999}
            className="category-group-buttons"
          > 
        {Object.entries(categoryGroups).map(([categoryGroup, icon]) => (
          <Button mx={2} p={1} py={0}
            key={categoryGroup}
            onClick={() => handleCategoryGroupClick(categoryGroup)}
            size={isMobile ? "xs" : "xs"}
            className={`btn ${selectedCategoryGroups.includes(categoryGroup) ? 'selected' : ''}`}
            bg={selectedCategoryGroups.includes(categoryGroup) ?  buttonBgColorCategoryGroup: "white.100"}
            color={selectedCategoryGroups.includes(categoryGroup) ?  buttonTextColorCategoryGroup:  "gray.200" }
            _hover={{ bg: selectedCategoryGroups.includes(categoryGroup) ?  buttonHoverCategoryGroup: "white.400"  }}
            _active={{ bg: selectedCategoryGroups.includes(categoryGroup) ?  buttonBgActiveCategoryGroup: "gray.900" }}
            _focus={{ boxShadow: "none" }}
          >
            <Box as="span" display="inline-block" marginRight={1}>
              <FontAwesomeIcon icon={icon} size="sm" />
            </Box>
            {categoryGroup}
          </Button>
        ))}
        </Flex>    </Flex>
      </>
    ) : (
      // Componente para pantalla de movil
      // <Menu>
      //   <MenuButton
      //     as={Button}
     
       
      //     leftIcon={<FontAwesomeIcon icon={faLayerGroup} />}
      //     rightIcon= {<IoIosArrowDown />}
        
      //     size={{ base: "xxs", sm: "xs", md: "xs" }}// Tamaño pequeño del botón
      //     paddingX={2} // Ajustar el padding horizontal
      //     margin={9}
      //     paddingY={1} // Ajustar el padding vertical

      //   >
        
      //   </MenuButton>
      //   <MenuList>
      //     {Object.entries(categoryGroups).map(([categoryGroup, icon]) => (
      //       <MenuItem
      //         key={categoryGroup}
      //         onClick={() => handleCategoryGroupClick(categoryGroup)}
      //         className={`btn ${selectedCategoryGroups.includes(categoryGroup) ? 'selected' : ''}`}
      //         bg={selectedCategoryGroups.includes(categoryGroup) ?  buttonBgColorCategoryGroup: "white.100"}
      //         color={selectedCategoryGroups.includes(categoryGroup) ?  buttonTextColorCategoryGroup:  "gray" }
      //         _hover={{ bg: selectedCategoryGroups.includes(categoryGroup) ?  buttonHoverCategoryGroup: "white.400"  }}
      //         _active={{ bg: selectedCategoryGroups.includes(categoryGroup) ?  buttonBgActiveCategoryGroup: "gray.900" }}
      //         _focus={{ boxShadow: "none" }}
      //       >
      //         <Box as="span" display="inline-block" marginRight={2}>
      //           <FontAwesomeIcon icon={icon} size="sm" />
      //         </Box>
      //         {categoryGroup}
      //       </MenuItem>
      //     ))}
      //   </MenuList>
      // </Menu>


    //   <Menu>
    //   <MenuButton
    //     as={Button}
   
     
    //     leftIcon={<FontAwesomeIcon icon={faLayerGroup} />}
    //     rightIcon= {<IoIosArrowDown />}
      
    //     size={{ base: "xxs", sm: "xs", md: "xs" }}// Tamaño pequeño del botón
    //     paddingX={2} // Ajustar el padding horizontal
    //     margin={9}
    //     paddingY={1} // Ajustar el padding vertical

    //   >
      
    //   </MenuButton>
    //   <MenuList>
    //     {Object.entries(categoryGroups).map(([categoryGroup, icon]) => (
    //       <MenuItem
    //         key={categoryGroup}
    //         onClick={() => handleCategoryGroupClick(categoryGroup)}
    //         className={`btn ${selectedCategoryGroups.includes(categoryGroup) ? 'selected' : ''}`}
    //         bg={selectedCategoryGroups.includes(categoryGroup) ?  buttonBgColorCategoryGroup: "white.100"}
    //         color={selectedCategoryGroups.includes(categoryGroup) ?  buttonTextColorCategoryGroup:  "gray" }
    //         _hover={{ bg: selectedCategoryGroups.includes(categoryGroup) ?  buttonHoverCategoryGroup: "white.400"  }}
    //         _active={{ bg: selectedCategoryGroups.includes(categoryGroup) ?  buttonBgActiveCategoryGroup: "gray.900" }}
    //         _focus={{ boxShadow: "none" }}
    //       >
    //         <Box as="span" display="inline-block" marginRight={2}>
    //           <FontAwesomeIcon icon={icon} size="sm" />
    //         </Box>
    //         {categoryGroup}
    //       </MenuItem>
    //     ))}
    //   </MenuList>
    // </Menu>
    <> </>
    )}

   







<Box   bg={'white'}
          _dark={{
            bg: '#0e121b',
          }} style={{ minHeight: 'calc(100vh - 50px)',padding:'3.8em 0 0 0' }} >
                 
 

<Flex    
   bg={'white'}
  _dark={{
    bg: '#0e121b',
  }}
  m={0} p={0}
  style={{ flexDirection: 'column' }}
  w={{ base: 'full', md: 'full' }}
  alignItems="center"
  justifyContent="center"
>
 
{isMobile && (
  <>
 
<Box bg={'white'}   _dark={{ bg :'#0e121b'}} mt={1.5} mb={2.5} style={{ width: isMobile ? "100%" : "44.2vw",  height: isMobile ? "4vh" : "44.2vw",display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  {Object.entries(categoryGroups).map(([categoryGroup, icon]) => (
     <Tooltip key={categoryGroup} label= {categoryGroup}  placement="bottom">
     <Button
          height={'4em'}
          width={'4.5em'}
          mx={'0.2em'} 
          p={0.5}
          pt={2}

          key={categoryGroup}
          onClick={() => handleCategoryGroupClick(categoryGroup)}
          size={isMobile ? 'xs' : 'xs'}
          className={`btn ${selectedCategoryGroups.includes(categoryGroup) ? 'selected' : ''}`}
          bg={selectedCategoryGroups.includes(categoryGroup) ? categoryColors[categoryGroup] : 'white.100'}
          color={selectedCategoryGroups.includes(categoryGroup) ? 'white' : 'gray.400'}
          _hover={{ bg: selectedCategoryGroups.includes(categoryGroup) ? categoryColors[categoryGroup] : 'white.400' }}
          _active={{ bg: selectedCategoryGroups.includes(categoryGroup) ? categoryColors[categoryGroup] : 'gray.1000' }}
          _focus={{ boxShadow: 'none' }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box as="span" display="inline-block" marginBottom={2} >
            <FontAwesomeIcon icon={icon} size="2x" />
          </Box>
          {/* <Box as="span" fontSize={9}>
            {categoryGroup}
          </Box> */}
        </Button>
        </Tooltip>
  ))}
     <Button
      key={'help'}
      bg="white"
      _dark={{ bg :'#0e121b'}}
      onClick={handleHelpButtonClick} 
      size={isMobile ? "xs" : "xs"}
      // className={`btn ${selectedCategoryGroups.includes(categoryGroup) ? 'selected' : ''}`}
      // bg={selectedCategoryGroups.includes(categoryGroup) ? buttonBgColorCategoryGroup : "white.100"}
      // color={selectedCategoryGroups.includes(categoryGroup) ? buttonTextColorCategoryGroup : "gray.400"}
      // _hover={{ bg: selectedCategoryGroups.includes(categoryGroup) ? buttonHoverCategoryGroup : "white.400" }}
      // _active={{ bg: selectedCategoryGroups.includes(categoryGroup) ? buttonBgActiveCategoryGroup : "gray.1000" }}
      _focus={{ boxShadow: "none" }}
      display="flex"    // Added display flex to the Button to allow aligning content
      flexDirection="column"    // Align content in a column layout
      alignItems="center"    // Center content horizontally
    >
      <Box as="span" display="inline-block" marginBottom={2}>    {/* Move icon above the text */}
         <FontAwesomeIcon icon={faQuestionCircle} size="2x" /> 
      </Box>
      <Box as="span" fontSize={9}>Ayuda</Box>    {/* Display categoryGroup below the icon */}
    </Button>
  
</Box>
{showHelpMessage && (
  <Fade in={showHelpMessage}>
  <Box
  width="95%"
  backgroundColor="white"
  borderRadius="md"
  px={3}
  py={2}
  m={2}
  color="gray.600"
   bg="whiteAlpha.900"
 _dark={{ color: 'gray.100',bg: 'gray.800' }}
  fontWeight={400}
  style={{
    display: 'flex',  // Use flexbox to align icon and text
    alignItems: 'center', // Align items vertically
  }}
>
  <FontAwesomeIcon icon={faPuzzlePiece } size="2x" style={{ marginRight: '10px' }} />
 
  <Text ml={2} mr={3} fontSize={12}>
  • Inicialmente las categorías están activas. <br />
  • Al hacer clic en los íconos de categoría, puedes enfocarte en tipos específicos
  de noticias y personalizar tu experiencia de lectura según tus gustos, estos filtros se mantendrán cuando vuelvas a ingresar al sitio.
  </Text>
   
</Box>
      </Fade>
    )}
 </>
 )}
    
 <Flex  m={0} p={0} style={{ width: isMobile ? "100%" : "auto" }} >




  {weatherData?.weather[0] && (
    
<Badge 
   color="gray.800"
   _dark={{ color: 'gray.100',bg: 'gray.800' }}
   bg="whiteAlpha.900"
 borderRadius="md" // Borde redondeado
 m={0} p={0}
 mb={1}
 alignItems="center" // Centrar verticalmente
  display="flex" // Mostrar como flex container
  style={{ width: isMobile ? "100%" : "44.2vw" , display: 'flex', alignItems: 'center', justifyContent: 'center' }}
 >
 
 
  <Text as="span"  m={0} p={0}
   alignItems="center" // Centrar verticalmente
   display="flex" // Mostrar como flex container
   style={{ padding :'0 1em'}}
   >
       <p style={{ width:'auto', fontSize: '0.9em',whiteSpace: 'normal' }}>
    {weatherData.name} </p>
    <Box as="span" display="inline-block"  p={1}>
    {getWeatherIcon(weatherData.weather[0].icon)}
    </Box>
    <Text fontSize="xl" fontWeight="bold" as="span" display="inline">
       {Math.floor(weatherData.main.temp)} <WiCelsius display="inline" style={{ verticalAlign: 'text-top', display: 'inline' }} />
    </Text> 
    <Text fontSize="sm" color="gray.500" as="span">
      <Box as="span" display="inline-block" style={{ display: 'inline', fontSize: '0.7em' }}>
        <FaAngleDown  style={{ display: 'inline', fontSize: '0.7em' }}/>{' '}
        {Math.floor(weatherData.main.temp_min)}
        <WiCelsius style={{ verticalAlign: 'text-top' , display: 'inline'}} />
      </Box> 
    
      <Box as="span" display="inline-block" style={{ display: 'inline', fontSize: '0.7em' }}>
        <FaAngleUp style={{ display: 'inline', fontSize: '0.7em' }}/>{' '}
        {Math.floor(weatherData.main.temp_max)}
        <WiCelsius style={{ verticalAlign: 'text-top', display: 'inline' }} />
      </Box>
    </Text>{' '}
    {/* {getWeatherDescription(weatherData.weather[0].main)} */}
  </Text>
  {/* {showWeatherButton && (  */}
  {!showForecast ? (
   <Button onClick={enableForecast}   m={2} size="sm" // Agregar esta línea para hacer el botón más pequeño
   fontSize="sm" // Ajustar el tamaño de la fuente del botón
   >        <FaAngleDown  style={{ display: 'inline', fontSize: '0.7em' }}/> 
    </Button>
    ):(
    <Button onClick={disableForecast}   m={2} size="sm" // Agregar esta línea para hacer el botón más pequeño
   fontSize="sm" // Ajustar el tamaño de la fuente del botón
   >        <FaAngleUp  style={{ display: 'inline', fontSize: '0.7em' }}/> 
    </Button>
    )}
     {/* )} */}
  {showWeatherButton && ( 
   <Button onClick={handleRequestLocationPermission}  mt={0}  size="sm" // Agregar esta línea para hacer el botón más pequeño
   fontSize="sm" // Ajustar el tamaño de la fuente del botón
   >        <FaSync style={{ display: 'inline', fontSize: '0.7em' }}/> 
    </Button>)}

    <div style={{ margin: '0 0.6em  0 0.4em '}}>
    {dolarValue !== null && (
        <p style={{  fontSize: '0.9em' }}>
          USD: {Math.floor(dolarValue)}
        </p>
      )}
      {ufValue !== null && (
        <p style={{   fontSize: '0.9em' }}>
          UF: {Math.floor(ufValue)}
        </p>
      )}

    </div>
</Badge>

 

  )}


</Flex>
 

  
  {weatherData2 && showForecast && (
    <Fade in={showForecast} unmountOnExit style={{ width: isMobile ? "100%" : "44.2vw" }} >
    <Badge
    
    color="gray.800"
    _dark={{ color: 'gray.100',bg: 'gray.800' }}
    bg="whiteAlpha.900"
    borderRadius="md"
    my={1} p={0} mt={0}
    alignItems="center"
    display="flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
    <div>
      <Box className="forecast"  p={1}  py={2} alignItems="center">
      {weatherData2.list.slice(numberOfItemsToShowStart, numberOfItemsToShow).map((item: any, index: number, arr: any[]) => {
          if (index % 2 !== 0) {
            return null;
          }
          const forecastDate = new Date(item.dt * 1000);
          const formattedDate = moment.utc(forecastDate).tz(userTimezone);
          // Cambiar el idioma de formato a español
          moment.locale('es');
          const day = formattedDate.format('dddd' );
          const time = formattedDate.format('hh:mm A' );

          // Verificar si el día ya ha sido mostrado
          const shouldDisplayDay = index === 0 || day !== new Date(arr[index - 2].dt * 1000).toLocaleDateString('es-ES', { weekday: 'long' });

          return (
            <Box key={item.dt} m={0} px={1} className="forecast-item" >
              {/* Mostrar el nombre del día solo si es necesario */}
              {shouldDisplayDay ? (
                   <Badge
                   bg="black"
                 color="white"
                 borderRadius="md"
                  m={0} px={1}
                  fontSize="2xs"
                  alignItems="center"
                  display="flex"
                 >
                <div className="forecast-day">{day}</div>
                </Badge>
              ) : (
                <Box className="forecast-day"       m={0} px={1}
                fontSize="2xs">&nbsp;</Box>
              )}
              
              <div className="forecast-date">
                <div style={{ fontSize: '0.9em' }}>{time}</div>
              </div>
              {getWeatherIcon(item.weather[0].icon,'2x')}
              <div  style={{ fontSize: '0.8em' }}>
               {Math.floor(item.main.temp_max)}  
              <WiCelsius style={{ verticalAlign: 'text-top', display: 'inline' }} /> 
               {Math.floor(item.main.temp_min)}  
              <WiCelsius style={{ verticalAlign: 'text-top', display: 'inline' }} />  
               
              </div>
            </Box>
          );
        })}
      </Box>
    </div>
    </Badge>
    </Fade>
   )} 
 
 

    </Flex>









                   <Flex
              
                bg="#fff"
                _dark={{
                  bg: '#0e121b',
                }}
                pt={'0.1em'}
                w={{ base: 'full', md: 'full' }}
                alignItems="center"
                justifyContent="center"
              >
   <Button onClick={goBackward }
   height={{ base: '2em', md: '2em' ,lg:'2em'}}>
  <Badge  
        borderRadius="2px"
        size="md"  
        style={{ width: '10%' , left: '10%', top:'10%', textAlign: 'center'  }}
        padding={{ base: '0.1em 3em', md: '0.1em 3em' ,lg:'0.1em  10em 0.1em 0 '}}
        
        colorScheme="white"
      >    <Flex alignItems="center" justifyContent="center">
          <Icon as={IoIosArrowBack } boxSize={4} mr={2} />
       
        </Flex>
      </Badge>
      </Button>
      
      <Badge  
        borderRadius="2px"
        size="md"  
        style={{   left: '0', top:'10%', textAlign: 'center', padding: '0.1em 0'  }}
        width={{ base: '50%', md: '30%' ,lg:'20%'}}
        colorScheme="white"
      >    <Flex alignItems="center" justifyContent="center">
          <Icon as={FaNewspaper} boxSize={4} mr={2} />
          <span>{selectedPeriod}</span>
        </Flex>
      </Badge>

      <Button onClick={goForward}
   height={{ base: '2em', md: '2em' ,lg:'2em'}}>
      
      <Badge  
        borderRadius="2px"
        size="md"  
        style={{ width: '17%' , left: '10%', top:'10%', textAlign: 'center'   }}
        padding={{ base: '0.1em 3em', md: '0.1em 3em' ,lg:'0.1em 0 0.1em 10em'}}
        colorScheme="white"
      >    <Flex alignItems="center" justifyContent="center">
          <Icon as={ IoIosArrowForward } boxSize={4} mr={2} />
        
        </Flex>
      </Badge>
      </Button>

   
      </Flex>


        {news
             .filter((item) =>
             selectedCategoryGroups.length > 0
               ? selectedCategoryGroups.includes(item.category_group)
               : true
           )
          //  .filter((item) =>
          //    selectedCategory ? item.category === selectedCategory : true
          //  )
           .map((item) => {
            // const urls: { id:number;url: string; site: string; title: string }[] = Array.isArray(item.urls)
            //   ? item.urls
            //   : JSON.parse(item.urls || '[]');

            return (
              
              // <Skeleton key={item.id} startColor="#edf0f2" endColor="#0e121b" height="20em" my="4" isLoaded={news.length > 0 ? true : false}>
              //parteaqui
              <article   key={item.id} >
              <Flex
                key={item.id}
                bg="#fff"
                _dark={{
                  bg: '#0e121b',
                }}
                px={{ base: '0', md: '' }}
                pt={2}
              
                pb={{ base: '5', md: '2' }}
                w={{ base: 'full', md: 'full' }}
                alignItems="center"
                justifyContent="center"
              >
                
                <Box
                  key={item.id} 
                  mx="auto"
                  px={{ base: '0', md: '2' }}
                  py={0}
                  
                  rounded={{ base: '', md: 'lg' }}
                  // shadow="lg"
                  bg="#f8f8f8"
                  _dark={{
                    bg: 'gray.800',
                  }}
                  maxW={{ base: '4xl',sm: 'xl', md: 'md', lg: 'xl', xl: '4xl' }}
                >

            {isMobile &&  (
                        <figure style={{  display: 'inline-block' }}>
                          {item.image_author ? (
                            <React.Fragment>
                                 <Link  to={'news/' + item.shortcut}>
                           <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <figure style={{ position: 'relative' }}>
                              <LazyLoadImage
                                src={imageUrl + btoa(item.id.toString()) + '.webp'}
                                alt=""
                                
                                effect="opacity"
                                style={{
                                  width: '100vw',
                                  height: '16em',
                                  objectFit: 'cover',
                                  borderRadius: '3px',
                                  objectPosition: 'top',
                                  padding:'0'
                             
                                }}
                              />
                              {/* <figcaption
                                style={{
                                  position: 'absolute',
                                  bottom: 0,
                                  left: 0,
                                  width: '100%',
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Ajusta el valor de transparencia según tus preferencias
                                  color: 'white',
                                  margin: '0 0 6px 0',
                                  textAlign: 'center',
                                  fontStyle: 'italic',
                                  fontSize: 'sm'
                                }}
                              >
                                Fuente: {item.image_author}
                              </figcaption> */}
                            </figure>
                          </div>
                          </Link>
                            </React.Fragment>
                          ) : null
                          //  (
                            // <div
                            //   style={{
                            //     width: '200px',
                            //     height: '300px',
                            //     backgroundColor: placeHolderImage,
                            //     borderRadius: '10px',
                            //   }}
                            // />
                          // )
                          }
                        </figure>
                     )}

           

                  <Box mt={1} display="flex" alignItems="center"   pr={2}>
                    <Box flexShrink={0} mr={1.5}   >
                    {!isMobile && (
                        <figure style={{    display: 'inline-block' }}>
                          {item.image_author ? (
                               <Link  to={'news/'+item.shortcut}>
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <figure style={{ position: 'relative' }}>
                                    <LazyLoadImage
                                      src={imageUrl + btoa(item.id.toString()) + '.webp'}
                                      alt=""
                                      effect="opacity"
                                      style={{
                                      width: '200px',
                                      minHeight: '180px',
                                      objectFit: 'cover',
                                      borderRadius: '10px',
                                      objectPosition: 'center top',
                                    
                                      }}
                                    />
                                    {/* <figcaption
                                      style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Ajusta el valor de transparencia según tus preferencias
                                        color: 'white',
                                        padding: '0.1em',
                                        textAlign: 'center',
                                        
                                        fontSize: '0.7em',
                                        borderRadius: '0.5em',
                                        margin: '0.3em 0'
                                      
                                      }}
                                    >
                                      Fuente: {item.image_author}
                                    </figcaption> */}
                                  </figure>
                                </div></Link>
                                           
              
                                                 ) : (
                            <div
                              style={{
                                width: '200px',
                                minHeight: '180px',
                                backgroundColor: placeHolderImage,
                                borderRadius: '10px',
                              }}
                            />
                          )}
                        </figure>
                     )}
                    </Box>

                    <Box>

                      
                      <Link
                       to={'news/'+item.shortcut}
                    
                        color="gray.700"
                       
                         
                        
                      >
                        
                        <Flex justifyContent="space-between" alignItems="center">
                    <chakra.span
                      fontSize="sm"
                      color="gray.600"
                      _dark={{ color: 'gray.400' }}
                    >
                      <Icon as={FaCalendarAlt} mr={2} />
                      {/* {moment(item.date_creation).tz("America/Santiago").format('LL LT')} */}
                      {formatWithDayName(item.date_creation, userTimezone)}
                    </chakra.span>
                    <chakra.span
                      px={2}
                      py={0.5}
                      bg={item.color}
                      color={item.color_text}
                      fontSize="xs"
                      
                      rounded="md"
                      // _hover={{
                      //   bg: item.color_pressed,
                      // }}
                    >
                      {item.category}
                    </chakra.span>
                    {/* <Box opacity={'40%'} rounded="md" width={{ base: '35px', md: '35px' }}> */}
                      {/* <Flag code={item.country}  height="16" /> */}
                      {/* <CircleFlag countryCode={item.country.toLowerCase()} /> */}
                    {/* </Box> */}
                  </Flex>
                        
                        <Heading as="h1"  size="md">
                      {item.title}
                    </Heading>
                  
                      </Link>
                      <chakra.p
  mt={1}
  color={item.spoiler === 1 && !item.showSpoiler ? 'black' : 'gray.600'}
  textDecoration={item.spoiler === 1 && !item.showSpoiler ? 'none' : 'none'}
  _dark={{
    color: item.spoiler === 1 && !item.showSpoiler ? 'gray.600' : 'gray.100',
  }}
  onClick={() => handleSpoilerClick(item.id)}
  _hover={{ cursor: !item.showSpoiler ? 'pointer' : 'default' }}
  style={{
    position: 'relative',
    borderRadius: '4px',
    padding: '0px',
    minHeight: '100px', // Ajusta la altura para centrar verticalmente el texto
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  {item.spoiler === 1 && !item.showSpoiler ? (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backdropFilter: 'blur(5px)',
          borderRadius: '4px',
        }}
      /> {item.country_name} - {item.summary}
      <div style={{ position: 'absolute', zIndex: 1 }}>
        Este contenido es un spoiler. Haz clic aquí para verlo.
      </div>
    </>
  ) : (
     item.country_name + item.summary
  )}
</chakra.p>
                      
              
 

<div style={{ position: 'relative', width: '100%', height: '32px' ,marginTop: '-10 px'}}>
  
    
  <Link
  to={'news/' + item.shortcut}
  key={item.shortcut}
 
  color="blue.500"
  
  style={{
    fontWeight: 'bold',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  }}
>
{/* <Flex mt={4} >
  {urls.filter((url, index, self) => index === self.findIndex((u) => u.site === url.site)).map((urlObj: { id:number;url: string; site: string; title: string }, index: number) => (
 
        <div   key={urlObj.id} 
          style={{
            position: 'absolute',
            top:5 ,
            left: `${index * 18}px`, // Ajusta la separación entre cada imagen
            zIndex: urls.length - index,
          }}
        >
          {urlObj.site ? (
            <img
              src={`/resources/image/${urlObj.site}-32x32.png`}
              alt=""
              style={{ borderRadius: '5px', width: '16px', height: '16px' }} // Ajusta el tamaño de las imágenes
            />
          ) : null}
        </div>
  
    ))}
  </Flex> */}
  <span style={{ marginLeft: 'auto', color: '#2C5282' }}>
  <Badge style={{ marginRight: '4px',marginLeft: 'auto',marginTop: '-2px' }}
    borderRadius="full"
    px={2}
    colorScheme="blue"
    fontSize="sm"
  >
    {item.urls_count}
  </Badge>Ver Articulos</span>

  <IoIosArrowForward style={{ marginRight: '4px' }} />

</Link>
 
</div>







               
                    </Box>
                  </Box>
                </Box>
              </Flex>
             </article>
     
            );
          })}
      </Box>
      {/* <ScrollButton direction="up" />
      <ScrollButton direction="down" /> */}
        {/* </AppStateProvider> */}
    </>
  );
};

export default News;
