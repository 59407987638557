import React, { useState } from 'react';
import { Flex, Button, useColorModeValue } from "@chakra-ui/react";
import { StarIcon, TimeIcon } from "@chakra-ui/icons";
import { useNavigate ,useLocation} from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const HomeButton = ( ) => {
  const [buttonText, setButtonText] = useState('Recientes');
 
  const bgColor = useColorModeValue("gray.100", "gray.700"); // Obtén el color de fondo según el tema actual
  // const buttonColorScheme = useColorModeValue('gray', 'grayDark');
  const buttonBgColor = useColorModeValue('gray', 'grayDark');
  const buttonTextColor = useColorModeValue('white', 'gray.800');
  const navigate = useNavigate();
  const location = useLocation();
  const handleHomeButtonClick = () => {
    navigate('/');
  };
 

  return (
    <Flex
    position="fixed"
    top={{ base: "0.8em", sm: "0.6em" }}
    right={{ base: "4em", sm: "7em", md: "7em" , lg: "7em", xl: "14em" }}
    zIndex={100000}
    width="100%"
    justifyContent="flex-end" // Alinea el contenido a la derecha
    // paddingRight={{ md: "12em", sm: "5em", xs: "5em",base: "5em" }} // Establece el espacio de relleno derecho en 2em
    >
      <Button
        onClick={handleHomeButtonClick}
 
        leftIcon={<FaArrowLeft />}
        color="current"
        bg={bgColor}
        size={{ base: "sm", sm: "md", md: "md" }}// Tamaño pequeño del botón
        paddingX={2} // Ajustar el padding horizontal
        paddingY={1} // Ajustar el padding vertical
      >
        Inicio
      </Button>
    </Flex>
  );
};

export default HomeButton;
