import React, { createContext, useState, useEffect } from 'react';

type AppStateProviderProps = {
  children: React.ReactNode;
};

type AppStateContextType = {
  currentMode: string;
  updateCurrentMode: (mode: string) => void;
};

export const AppStateContext = createContext<AppStateContextType>({
  currentMode: 'Destacadas',
  updateCurrentMode: () => {},
});

export const AppStateProvider = ({ children }: AppStateProviderProps) => {
  const [currentMode, setCurrentMode] = useState('');

  const updateCurrentMode = (mode: string) => {
    setCurrentMode(mode);
    localStorage.setItem('currentMode', mode);
  };

  useEffect(() => {
    // Retrieve the currentMode from localStorage on component mount
    const storedCurrentMode = localStorage.getItem('currentMode');
 
    if (storedCurrentMode === null || storedCurrentMode === '') {
      setCurrentMode('Destacadas');
    } else {
      setCurrentMode(storedCurrentMode);
    }
  }, []);

  useEffect(() => {
    // Save the currentMode to localStorage whenever it changes
    localStorage.setItem('currentMode', currentMode);
  }, [currentMode]);

  const value = {
    currentMode,
    updateCurrentMode,
  };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};
