import React from 'react';
import { Box, Flex, Icon, useColorModeValue, chakra, Image, Link } from '@chakra-ui/react';
import { FaEnvelope, FaFacebook } from 'react-icons/fa';
import { AiOutlineQuestionCircle } from 'react-icons/ai'; // Importa el ícono de ayuda

const Footer = () => {
  const lightSpr = "/resources/image/sprcl-light.svg";
  const darkSpr = "/resources/image/sprcl-dark.svg";
  const themeSpr = useColorModeValue(darkSpr, lightSpr);

  const lightPet = "/resources/image/pet-light.svg";
  const darkPet = "/resources/image/pet-dark.svg";
  const themePet = useColorModeValue(darkPet, lightPet);

  return (
    <Box
      as="footer"
      w="full"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      mt="auto" // Añade esta línea para empujar el Footer hacia la parte inferior
    >
      <Flex
        flexDir={{
          base: "row",
          sm: "row",
        }}
        align="center"
        justify="space-between"
        px="1"
        h="3em"
        maxW="container.lg"
        mx="auto"
      >
        <chakra.p
          color="gray.800"
          _dark={{
            color: "white",
          }}
          flex="1"
          textAlign="left"
          fontSize={{ base: "0.5em", sm: "xs", md: "xs" }}
        >
          Contenidos bajo licencia Creative Commons (CC-BY-NC)
        </chakra.p>

        <Flex
          flex={{ base: "auto", sm: "auto" }}
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src={themePet}
            alt="Logo SPR.CL"
            boxSize="3em"
            style={{ imageRendering: "auto", marginRight: "0.5em" }}
          />
        </Flex>

        <Flex
          flex={{ base: "auto", sm: "auto" }}
          alignItems="center"
          justifyContent="center"
        >
          <Box mx="1">
            <Link href="mailto:contacto@niows.com" isExternal>
              <Icon as={FaEnvelope} boxSize={4} />
            </Link>
          </Box>
          <Box mx="1">
            <Link href="https://www.facebook.com/niows.chile" isExternal>
              <Icon as={FaFacebook} boxSize={4} />
            </Link>
          </Box>
          <Box mx="1">
            <Link href="how/"  >
              <Icon as={AiOutlineQuestionCircle} boxSize={4} />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
