import React, { useState, useEffect,forwardRef,useImperativeHandle   } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  useBreakpointValue,
  Flex,
 
  // Button,
  useColorModeValue,
  Skeleton,
   Text,
  Icon, Badge, 
  // Fade,
} from '@chakra-ui/react';
 
import { faGlobe, faBullseye, faHeart, faBook, faFutbol, faBriefcase, faFlask, faSchool, faUsers, faLeaf,faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
// import { CSSProperties } from 'react';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/es';
 
import { Scrollbar } from 'react-scrollbars-custom';
import {  FaExclamationCircle,FaFire } from 'react-icons/fa';
import { addCountryNamesToItems } from './Helpers'; 

// import Flag from 'react-world-flags';
// import { CircleFlag } from 'react-circle-flags';
 
moment.tz.setDefault('America/Santiago');

moment.locale('es');

// Define una interfaz que incluya la función fetchData
interface WeekRef {
  fetchData: () => void;
}

type NewsItem = {
  id: number;
  title: string;
  summary: string;
  date_creation: string;
  country: string;
  category: string;
  image: any;
  urls?: { url: string; site: string; title: string }[];
  relatedNews?: { title: string; url: string }[];
  image_author: string;
  color: string; // Agregar la propiedad 'color' de tipo string
  color_text: string; // Agregar la propiedad 'color_text' de tipo string
  shortcut:string;
  urls_count:number;
};
 
type Props = {
  show: boolean;
};

const Week = forwardRef((props:Props, ref) => {
  const { show } = props;
  // const isMobile = useBreakpointValue({ base: true, md: false });
  // const imageWidth = useBreakpointValue({ base: '100%', md: '200px' });
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
 
  const [news, setNews] = useState<NewsItem[]>([]); // Agregar esta línea para declarar el estado "news"

 
 
  // const handleCategoryClick = (category: string | null) => {
  //   setSelectedCategory(category);
  // };
  const imageUrl:any= 'https://niows.com/resources/image/news/'
  const endpointUrl: any = 'https://niows.com/';
  // const endpointUrl: any = 'http://10.10.10.100:8888/';
  const [endpoint, setEndpoint] = useState(endpointUrl+'api/week');



  // const handleEndpointChange = () => {
  //   setSortBy(sortBy === "urls_count" ? "id" : "urls_count")
  // };

  // const buttonColorScheme = useColorModeValue('gray', 'grayDark');
  const buttonBgColor = useColorModeValue('gray', 'grayDark');
  const buttonTextColor = useColorModeValue('white', 'gray.800');

  // const placeHolderImage = useColorModeValue('#e0e0e0', '#171d29');
  const [sortBy, setSortBy] = useState("urls_count");

  const [totalUrls, setTotalUrls] = useState([]);

  const categoryIcons:any = {
    Todas: faGlobe,
    "Crimen y Justicia": faBullseye,
    Entretenimiento: faHeart,
    Política: faBook,
    Deportes: faFutbol,
    Salud: faBriefcase,
    Educación: faFlask,
    Sociedad: faSchool,
    "Medio Ambiente": faUsers,
    "Ciencia y Tecnología": faLeaf,
    "Economía": faMoneyBill
  };

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState<number | null>(null); 
  const FIVE_MINUTES = 5 * 60 * 1000; // 5 minutes in milliseconds

  // Fetch news data from the endpoint
 // Fetch news data from the endpoint
 const fetchData = () => {
  fetch(endpoint)
    .then((response) => response.json())
    .then((data) => {
      if (data.length === 0) {
        setHasError(true);
      } else {
        // const compareFunction = sortBy === "urls_count"
        //   ? (a:any, b:any) => b.urls_count - a.urls_count // Ordenar por urls_count de forma descendente
        //   : (a:any, b:any) => b.id - a.id; // Ordenar por id de forma ascendente
        // data.sort(compareFunction);
        data = addCountryNamesToItems(data)
        setNews(data);

        // Calcular el total de URLs
        const totals = data.map((item:any) => item.urls_count);
        setTotalUrls(totals);
      }

      setIsLoading(false);
      setLastFetchTime(Date.now()); // Guardar la fecha de carga actual en milisegundos
      localStorage.setItem('weekData', JSON.stringify(data)); // Guardar la información en el localStorage
      localStorage.setItem('lastFetchTime', Date.now().toString()); // Guardar la fecha de carga en el localStorage
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
      setHasError(true);
    });
};


useImperativeHandle(ref, () => ({
  fetchData, // Expose the function through the ref
}));


useEffect(() => {
  const storedData = localStorage.getItem('weekData');
  const storedFetchTime:any = localStorage.getItem('lastFetchTimeWeek');
  const shouldFetchData =
    !storedData || !storedFetchTime || Date.now() - storedFetchTime > FIVE_MINUTES;

  if (shouldFetchData) {
    fetchData();
  } else {
    setNews(JSON.parse(storedData));
    setIsLoading(false);
  }
}, [endpoint, sortBy]);

 
 
  // const selectedCategoryName = selectedCategory || "Todas";


  if (isLoading) {
    return (

      <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
      <Box>
        <Skeleton width={{ base: "80vw", sm: "60vw", md: "100vw", lg: "80vw", xl: "60vw"  }} height="80vh" borderRadius="8px"/>
                 
      </Box>
    </Flex>
    );
  }
 

  if (hasError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
        flexDirection="column"
      >
        <FaExclamationCircle size={72} color="white" />
        <Text fontSize="xl" fontWeight="bold" color="white.500" textAlign="center">
          ¡Oops! Parece que no se pudieron cargar las noticias. Inténtalo de nuevo más tarde.
        </Text>

     
      </Box>
    );
  }

  if (!news) {
    return null;
  }
  return (
    <>

{!show &&

<Box  maxHeight="45vh" overflowY="auto" style={{ width: '17%', position: 'fixed', left: '0%', top:'7em'  , justifyContent: 'center' }}  bg="#f8f8f8"
_dark={{
  bg: 'gray.800',
}}  >

<Box  
  borderRadius="6px"
  style={{ textAlign: 'center' }}
  fontWeight={600} fontSize={15}  fontFamily="heading"
>    <Flex alignItems="center" justifyContent="center">
    <Icon as={FaFire} boxSize={4}  mr={2} />
    <span>Tendencia Semanal</span>
  </Flex>
</Box>

 <Box  maxHeight="35vh" overflowY="auto"   bg="#f8f8f8"
 _dark={{
   bg: 'gray.800',
 }} borderRadius={5}>
 <Scrollbar style={{  height: "34vh" }}>
 




                      
        {news
          .filter((item) => (selectedCategory ? item.category === selectedCategory : true))
          .map((item) => {

            return (
              <Link    key={item.id} to={'news/' + item.shortcut} color="gray.700">
              <Box display="flex" alignItems="center" style={{ margin: '10px auto' }}>
                <Box px={'0.2em'} py={'0.1em'}>
                <div
                  style={{
                    width: '3vw',
                    height: '3vw',
                    borderRadius: '5px',
                    overflow: 'hidden',
                  }}
                >
                  <LazyLoadImage
                    src={imageUrl + btoa(item.id.toString()) + '.webp'}
                    alt=""
                    effect="opacity"
                    style={{
                      width: '5vw',
                      objectFit: 'cover',
                      borderRadius: '5px',
                      objectPosition: 'top',
                      transition: 'opacity 1s linear',
                    }}
                  />
                </div>
                </Box>
                <Box px={1}>
                  <Box
                    bg={item.color}
                    color={item.color_text}
                    fontSize="xs"
                    rounded="md"
                    lineHeight="1.2"
                    display="block"
                  >          
                  <Badge
                  style={{ marginRight: '4px', marginLeft: 'auto', marginTop: '-2px' }}
                  borderRadius="full"
                  colorScheme="gray"
                  fontSize="2xs"
                >
                  {moment(item.date_creation).format('D MMM')}
                  {/* <FontAwesomeIcon icon={categoryIcons[item.category]} size="sm" style={{ marginLeft: '4px'}}/> */}
                </Badge>
                    {item.title}
                    <Badge
                      style={{ marginRight: '4px', marginLeft: 'auto', marginTop: '-2px' }}
                      borderRadius="full"
                      colorScheme="gray"
                      fontSize="2xs"
                    >
                      {item.urls_count}
                    </Badge>
                  </Box>
                </Box>
              </Box>
            </Link>
            );
          })}
     
          </Scrollbar>
          </Box>
          </Box>
 } </>
  );
});

export default Week;
